<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Usuario"
            label-for="userCat"
          >
            <v-select
              id="userCat"
              v-model="item.userId"
              label="userFullName"
              :options="this.usersCat"
              :reduce="option => option.userId"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Auxiliares"
            label-for="auxiliaries"
          >
            <v-select
              id="auxiliaries"
              v-model.number="item.auxId"
              label="auxCode"
              :options="auxCodes"
              disabled=""
              :reduce="option => option.auxId"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Estado"
            label-for="approverStatus"
          >
            <v-select
              id="status"
              v-model="item.status"
              label="status"
              :options="statuses"
              :reduce="option => option.idStatus"
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            :to="'/cda/admin/auxiliary-approvers/' + this.$route.params.origin"
          >
            Cancelar
          </b-button>
        </b-col>

        <p>{{ errorMessage }}</p>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      item: {},
      loading: false,
      errorMessage: '',
      usersCat: [],
      auxCodes: [],
      statuses: '',
      currentAuxCode: 10
    }
  },
  computed: {
  },
  created() {
    this.loadCats()
    console.log('h: ', this.$route.params)

  },
  methods: {
    async loadCats() {
      try {
        this.loading = true
        const users = await this.$http.get(`${environment.uri}/auxiliaries/approvers`)
        this.usersCat = users.data
        this.item.auxId = Number(this.$route.params.origin)
        const auxCodes = await this.$http.get(`${environment.uri}/auxiliaries/all`)
        this.auxCodes = auxCodes.data
        this.statuses = this.$store.getters['catalog/allStatuses']
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },

    save(item) {
      this.loading = true
      this.errorMessage = ''
      this.$http.post(`${environment.uri}/auxiliaries/approver/create`, item)
          .then(() => {
            this.loading = false
            this.$router.replace('/cda/admin/auxiliary-approvers/' + this.$route.params.origin)
          })
          .catch(error => {
            console.error(error)
            this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
            this.loading = false
          })
    },
  },
}
</script>

<style scoped>

</style>
